import React from 'react';
import Home from "./Home";
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';




const App=()=> {
  return(
    <Home></Home>
  )
    
  
}

export default App;
