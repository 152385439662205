export const ID_BOARD="60c27d056efcfe2206fa2f07";
export const ID_LIST_INBOX="60c27d3be4391186d597ea19";
export const GREEN="60c27d05c8246138307e163c";
export const YELLOW='60c27d05c8246138307e163e';
export const ORANGE='60c27d05c8246138307e1640';
export const RED='60c27d05c8246138307e1642';

export const area=[
    {"nombre":"Legales"},
    {"nombre":"Mesa de Entrada"},
    {"nombre":"Jefatura de Gabinete"},
    {"nombre":"Habitad"},
    {"nombre":"Catastro"},
  ];

export const prioridad=[
    {"nombre":"Normal",'color':'#61bd4f','horas': 168,idColor:GREEN,id:0},
    {"nombre":"Media ","color":"#f2d600",'horas': 120,idColor:YELLOW,id:1},
   
    {"nombre":"Media-Alta",'color':'#ff9f1a','horas': 72,idColor:ORANGE,id:2},
    {"nombre":"Urgente",'color':'#eb5a46','horas': 24,idColor:RED,id:3}
]