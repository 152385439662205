import React from 'react';
import {Form,Card, Container} from 'react-bootstrap';
import axios from 'axios';

import {area,prioridad} from './service/constantes';
import './App.css';
import { useState } from 'react';



const Home=()=> {
 
  const [email,SetEmail]=useState('')
  const [nombre,SetNombre]=useState('')
  const [file,SetFile]=useState()
  const [telefono,SetTelefono]=useState('')
  const [solicitud,SetSolicitud]=useState('')
  const [isSubmitting,setIsSubmitting]=useState(false)
  const date=new Date()
  const fecha=date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()
  const hora=date.getHours()+":"+date.getMinutes()
  console.log("fecha",fecha)
  console.log("hora",hora)
  console.log(date.toISOString())
  const changeHandleNombre=(e)=>{
    console.log(e.target.value)
    SetNombre(e.target.value)
  }
  const changeHandleEmail=(e)=>{
    console.log(e.target.value)
    SetEmail(e.target.value)
  }
  const changeHandleTelefono=(e)=>{
    console.log(e.target.value)
    SetTelefono(e.target.value)
  }
  const changeHandleSolicitud=(e)=>{
    console.log(e.target.value)
    SetSolicitud(e.target.value)
  }



const formularioSubmit=(e)=>{
  setIsSubmitting(true)
  e.preventDefault()
  console.log(e.target.area.value)
  console.log(e.target.prioridad.value)
  const dpto=e.target.area.value
  const pri =e.target.prioridad.value


  
  const data = {
    "name":solicitud,
	  "desc":"Fecha: "+fecha+"\nHora: "+hora+"\nEmail:"+ email +"\nArea:"+dpto+"\nTelefono:"+telefono+"\nSolicitud:" +solicitud,
	  "idList":"60c27d3be4391186d597ea19",
	  "idMembers": []
  }
  console.log(data)
  
  /*axios.post("https://api.trello.com/1/cards?key=25156cff7a732390601490d17b7bf3bc&token=1a402a8b7bd05724dfc5aea0140b184978aa81008f22109c22159a595e17190d",data)
  .then((resp)=>{console.log(resp)})
  .catch((error)=>{console.log(error)})*/
  const url = 'https://api.trello.com/1/cards?key=25156cff7a732390601490d17b7bf3bc&token=1a402a8b7bd05724dfc5aea0140b184978aa81008f22109c22159a595e17190d';
  const formData = new FormData();
  if(file !== undefined)formData.append('fileSource',file)
  console.log(file)
  
  formData.append("name",solicitud)
  formData.append("idList","60c27d3be4391186d597ea19")
  formData.append("desc","Fecha: "+fecha+"\nHora: "+hora+"\nEmail:"+ email +"\nArea:"+dpto+"\nTelefono:"+telefono+"\nSolicitud:" +solicitud)
  formData.append('idLabels',[prioridad[pri].idColor]);
 date.setHours(date.getHours()+prioridad[pri].horas)
  formData.append('due',date.toISOString());
  axios.post(url, formData)
  .then((resp)=>{
    console.log(resp)
    setIsSubmitting(false)
    window.alert("Tu requerimirnto a sido enviado\nTu numero de Ticket es :"+resp.data.id)
  })
  .catch((error)=>{
    setIsSubmitting(false)
    window.alert("Error en el envio intentalo en unos minutoos")
    console.log(error)
  })
}
const onChangeFile=(e)=> {
  console.log("File",e.target.files[0])
  SetFile(e.target.files[0])

}

  
 
  


  return (
    <Container>
      <Card className="text-center" bg="info" text="light">
        <Card.Header><h1>Help Desk</h1></Card.Header>
        <Card.Subtitle><h3>Por favor completa el siguiente Formulario</h3></Card.Subtitle>
        <Card.Body>
        <Form onSubmit={formularioSubmit}>
            <Form.Group controlId="nombre">
              <Form.Label>Nombre y apellido *</Form.Label>
              <Form.Control
               type="text"
               placeholder="Nombre y Apellido" 
               name="nombre" 
               onChange={changeHandleNombre}
               value={nombre}
               required={true}
               />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email address *</Form.Label>
              <Form.Control 
              type="email" 
              placeholder="nombre@edominio.com" 
              name="email" 
              onChange={changeHandleEmail}
              value={email}
              required={true}
              />
            </Form.Group>
            <Form.Group controlId="area">
              <Form.Label>Indicanos cual es tu area *</Form.Label>
              <Form.Control as="select">
                  <option >Selecciona una opcion </option>
                {area.map((data,i)=>{
                  return(
                    <option value={data.nombre} key={i}>{data.nombre}</option>
                  )
                })}
                
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="telefono">
              <Form.Label>Telefono de Contacto *</Form.Label>
              <Form.Control
               type="number" 
               
               onChange={changeHandleTelefono}
              value={telefono}
              required={true}
               />
               </Form.Group>
                  <Form.Group controlId="solicitud">
                    <Form.Label>Dejanos tu solicitud *</Form.Label>
                    <Form.Control 
                    as="textarea" 
                    rows={3} 
                    onChange={changeHandleSolicitud}
                    value={solicitud}
                    required={true}
                    />
                  </Form.Group>
                 
            <Form.Group controlId="prioridad">
              <Form.Label>Indicanos cual es tu prioridad *</Form.Label>
              <Form.Control as="select"  onClick={console.log("Cambia")}>
                  <option >Selecciona una opcion </option>
                {prioridad.map((data,i)=>{
                  return(
                    <option value={data.id} key={i} style={{backgroundColor:data.color}}>{data.nombre}</option>
                  )
                })}
                
              </Form.Control>
                </Form.Group>
                  <Form.File id="adjuntos">
                    <Form.File.Label>Archivos adjuntos</Form.File.Label>
                    <Form.File.Input  onChange={onChangeFile}/>
                  </Form.File>
                  
                  <button 
              type="submit"
               disabled={isSubmitting}
               style={{width:"100%",marginTop:"10px", background: "rgba(153, 198, 187, 0.89)"}}
               >
                {isSubmitting ? 'Enviando' : 'Enviar'}
              </button>
      </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Home;
